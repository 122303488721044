import { render, staticRenderFns } from "./layer-bill-hexiao.vue?vue&type=template&id=ed85b4aa&scoped=true&"
import script from "./layer-bill-hexiao.vue?vue&type=script&lang=js&"
export * from "./layer-bill-hexiao.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed85b4aa",
  null
  
)

export default component.exports