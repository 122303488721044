<template>
    <r-e-dialog title="账单核销" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh" width="550px"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="核销金额" prop="amount">
                    <el-input-number v-model="formPublish.amount" style="width: 365px;" :precision="2" :step="1" placeholder="请输入核销金额" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="付款方式" prop="paymentMethod">
                    <el-select v-model="formPublish.paymentMethod" style="width: 365px;" placeholder="请选择付款方式">
                        <el-option v-for="(item,index) in paymentTypeList" :key="index" :label="item.codeName" :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {cancelPaybill} from '@/api/shops-lease';
    import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    import {timeFormat, numberFormat, ParseFloatFormat, ParseIntFormat} from "@custom/index";
    export default {
        name: "layer-bill-hexiao",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    paymentMethod: null,
                    transactionCode: null,
                    amount: 0,
                    payBillUuid: null,
                    leasorUuid: null,
                    comment: null,
                },
                rules: {
                    amount: [{required: true, message: '请输入核销金额', trigger: 'blur'},],
                    paymentMethod: [{required: true, message: '请选择付款方式', trigger: 'change'}]
                },
                paymentTypeList: [],
                loadingOptions: {
                    lock: true,
                    text: "请您稍后...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        methods:{
            openDialog(data){
                let {leasorUuid,bills} = data;
                let that = this;
                const transactionCode = Math.random();
                const payBillUuid = bills.map(i => i.uuid);
                const amounts = bills.map(i => i.receivableAmount);
                const amount = amounts.reduce((prev, curr) => {return prev + curr});
                let oldFormPublish = that.formPublish;
                that.formPublish = {...oldFormPublish, transactionCode, leasorUuid, payBillUuid, amount: ParseFloatFormat(amount)};
                that.dialogVisible = true;
            },
            clickSubmit(){
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        this.$confirm('此操作将永久核销此账单, 是否继续?', '提示', {type: 'warning'})
                            .then(() => {
                                let {paymentMethod, transactionCode, amount, payBillUuid, leasorUuid} = that.formPublish;
                                amount = ParseIntFormat(amount);
                                const loadingOptions = that.loadingOptions;
                                const loading = that.$loading({...loadingOptions});
                                cancelPaybill({paymentMethod, transactionCode, amount, payBillUuid, leasorUuid}).then(res => {
                                    loading.close();
                                    MessageSuccess("核销成功");
                                    that.clickCancel();
                                }).catch(err => loading.close());
                            })
                            .catch(() => {});
                    }
                });
            },
            clickCancel(){
                let that = this;
                that.$emit("get-data");
                that.formPublish = {
                    paymentMethod: null,
                    transactionCode: null,
                    amount: 0,
                    payBillUuid: null,
                    leasorUuid: null,
                    comment: null
                };
                that.dialogVisible = false;
            },
        },
        async mounted() {
            this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500200");
        },
    }
</script>

<style scoped>

</style>